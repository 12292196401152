import { ReactElement, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Account from './Account';
import { RootState, ThunkDispatchType, actions } from '../../../store';
import { PAGE_URL } from '../../../constants';
import { useNavigate } from 'react-router';

export const AccountContainer = (): ReactElement => {
  const auth = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch<ThunkDispatchType>();
  
  const navigate = useNavigate();

  const [errors, setErrors] = useState<string[]>([]);

  const handleValidatePassword = (currentPassword: string, newPassword: string, newPassword2: string) => {
    if (!currentPassword || !newPassword) {
      setErrors(['You must provide your current password and a new password']);
    } else if (currentPassword === newPassword) {
      setErrors(['The new password is the same as your current password']);
    } else if (newPassword !== newPassword2) {
      setErrors(['The \'New Password\' and \'Confirm New Password\' fields do not match']);
    } else {
      return true;
    }

    return false;
  };

  const handleChangePassword = async (currentPassword: string, newPassword: string): Promise<boolean> => {
    setErrors([]);
    let success = false;
    try {
      const res = await dispatch(actions.auth.changePassword(currentPassword, newPassword));
      if ('errorType' in res && 'message' in res) {
        setErrors([res.message as string]);
      } else {
        success = true;
      }
    } catch (error) {
      console.log(error);
    }
    return success;
  };

  const handleSaveProfile = (
    { firstName, lastName }: { firstName: string; lastName: string },
    profilePic?: { file: File, fileName: string },
  ) => {
    return dispatch(actions.auth.updateProfile({
      first_name: firstName,
      last_name: lastName,
    }, profilePic?.file, profilePic?.fileName));
  };

  const onChangeEmail = async (email: string): Promise<boolean> => {
    const changedEmailRes = await dispatch(actions.auth.startAmplifyChangeEmail(email));
    if (changedEmailRes.nextStep.updateAttributeStep === "DONE" && auth.user) {
      navigate(PAGE_URL.CHANGE_EMAIL + `/?ogEmail=${auth.user.email}&newEmail=${email}`);
    }
    return false;
  };

  const logout = () => dispatch(actions.auth.logout());

  return <Account
    auth={auth}
    errors={errors}
    onValidatePassword={handleValidatePassword}
    onChangePassword={handleChangePassword}
    onSaveProfile={handleSaveProfile}
    logout={logout}
    onChangeEmail={onChangeEmail}
  />;
};

export default AccountContainer;
