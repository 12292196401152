import { ReactElement } from 'react';

import { RootState, SubscriptionDetails } from '../../../store';
import Subscription from './Subscription';
import { BILLING_INTERVAL, TIER, USER_ROLE } from '../../../constants';
import { getBillingSession } from '../../../utils/paymentUtils';
import { useSelector } from 'react-redux';



interface ComponentProps {
  paymentButtonDisabled: boolean;
  paymentDialogError: string;
  paymentRedirectDialogOpen: boolean;
  clearPaymentDialogError: () =>  void;
  onChangeSubscription: (
    newTier: TIER | null, interval: BILLING_INTERVAL | null, quantity: number | null, 
    newPromoCode: string | null, isPreview: boolean, prorationDate: string | null
  ) => Promise<SubscriptionDetails | undefined>;
}

type Props = ComponentProps;

export const SubscriptionContainer = ({
  onChangeSubscription,
  paymentButtonDisabled, paymentDialogError, paymentRedirectDialogOpen,
  clearPaymentDialogError
}: Props): ReactElement => {

  const user = useSelector((state: RootState) => state.auth.user);
  const subscriptionDetails = useSelector((state: RootState) => state.organization.subscriptionDetails);
  const organization = useSelector((state: RootState) => state.organization);
  
  const handleOpenBillingPortal = (): void => {
    getBillingSession().then(url => {
      window.open(url, '_blank');
    });
  };
  return (
    <>
      <Subscription
        clearPaymentDialogError={clearPaymentDialogError}
        isOnContract={organization.is_on_contract}
        subscriptionDetails={subscriptionDetails}
        isStripeManager={!!user?.permissions.STRIPE_SUBSCRIPTION_MGMT}
        accountOwner={organization?.owner}
        onChangeSubscription={onChangeSubscription}
        confirmButtonDisabled={paymentButtonDisabled}
        dialogError={paymentDialogError}
        redirectDialogOpen={paymentRedirectDialogOpen}
        onOpenBillingPortal={handleOpenBillingPortal}
        cancelationSurveyId={user?.active_license.user_role === USER_ROLE.INDIVIDUAL
          ? (import.meta.env.VITE_INDIVIDUAL_CANCELATION_TYPEFORM_ID || "")
          : (import.meta.env.VITE_CANCELATION_TYPEFORM_ID || "")}
      />
    </>
  );
};

export default SubscriptionContainer;