import { ReactElement, useEffect, useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  RootState, ThunkDispatchType, UserPrefs, actions,
  SchedulingPreferences, GlobalModalComponentName, SchedulingPreferenceUpdate
} from '../../../store';
import { DigestOptions, DIGEST_OPTIONS, DueOptions, DUE_OPTIONS, UserPreferences } from './UserPreferences';
import { isEqual } from 'lodash-es';
import { useMountEffect } from '../../../utils/hooks';
import { PAGE_URL } from '../../../constants';
import { useNavigate } from 'react-router';
import { useDebouncedCallback } from 'use-debounce';
import { checkForGoogleGrant, checkForMicrosoftGrant } from '../../../utils/authUtils';



const getSelectValue = (prefs: UserPrefs | undefined, options: DueOptions | DigestOptions): Array<string> => {
  if (prefs) {
    return Object.values(options).filter(key => {
      return Boolean(prefs[key as keyof UserPrefs]);
    });
  }
  return [];
};

export const UserPreferencesContainer = (): ReactElement => {

  const dispatch = useDispatch<ThunkDispatchType>();

  const user = useSelector((root: RootState) => root.auth.user);
  const schedulingPrefs = useSelector((root: RootState) => root.schedule.schedulingPrefs);
  const presetLocations = useSelector((root: RootState) => root.schedule.presetLocations);
  const zoomSettings = useSelector((root: RootState) => root.schedule.zoomSettings);

  const fetchSchedulingPreferences = () => dispatch(actions.schedule.fetchSchedulingPreferences());
  const updateSchedulingPrefs = (prefs: SchedulingPreferenceUpdate) =>
    dispatch(actions.schedule.updateSchedulingPrefs(prefs));
  const fetchPresetLocations = () => dispatch(actions.schedule.fetchPresetLocations());
  const fetchZoomSettings = () => dispatch(actions.schedule.fetchZoomSettings());
  const openGlobalModal = (componentName: GlobalModalComponentName) => 
    dispatch(actions.globalModal.openModal(componentName));

  const [dueReminders, setDueReminders] = useState<Array<string>>([]);
  const [digestReminders, setDigestReminders] = useState<Array<string>>([]);

  const navigate = useNavigate();

  const hasMicrosoftGrant = useMemo(() => (
    !!(user && checkForMicrosoftGrant(user.oauth_grant_details))
  ), [user]);

  const hasGoogleGrant = useMemo(() => (
    !!(user && checkForGoogleGrant(user.oauth_grant_details))
  ), [user]);

  useMountEffect(() => {
    fetchSchedulingPreferences();
    fetchPresetLocations();
    fetchZoomSettings();
  });

  useEffect(() => {
    const newDigestReminders = getSelectValue(user?.prefs, DIGEST_OPTIONS);
    if (!isEqual(newDigestReminders, digestReminders)) {
      setDigestReminders(newDigestReminders); 
    }

    const newDueReminders = getSelectValue(user?.prefs, DUE_OPTIONS);
    if (!isEqual(newDueReminders, dueReminders)) {
      setDueReminders(newDueReminders);
    }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.prefs]);  

  const handleSchedulingPrefChange = useCallback((pref: Partial<SchedulingPreferences>) => {
    if (schedulingPrefs?.user_prefs) {
      const id = schedulingPrefs?.user_prefs?.id;
      if (!isEqual({ ...schedulingPrefs?.user_prefs, ...pref }, schedulingPrefs?.user_prefs)) {
        updateSchedulingPrefs({id, ...pref});
      }
    }
  // Need deep compare
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(schedulingPrefs)]);

  const handleSchedulingPrefChangeDebounced = useDebouncedCallback((pref: Partial<SchedulingPreferences>) => {
    handleSchedulingPrefChange(pref);
  }, 400);

  const handleUpdateDefaultSecondaryTz = useCallback((idx: number, newTz: string | null) => {
    if (schedulingPrefs?.user_prefs && schedulingPrefs.user_prefs.default_secondary_timezone) {
      const currentDefaultTzs = schedulingPrefs.user_prefs.default_secondary_timezone;
      const newDefaultTz = newTz === "null" ? null : newTz;
      const secondaryTz = [...currentDefaultTzs.slice(0, idx), newDefaultTz, ...currentDefaultTzs.slice(idx + 1)];
      secondaryTz[idx] = newTz === "null" ? null : newTz;
      handleSchedulingPrefChange({
        id: schedulingPrefs.user_prefs.id,
        default_secondary_timezone: secondaryTz,
      });
    }
  // Need deep compare
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleSchedulingPrefChange, JSON.stringify(schedulingPrefs)]);

  const handleAddDefaultSecondaryTz = useCallback(() => {
    if (schedulingPrefs?.user_prefs) {
      const currentDefaultSecondaryTz = schedulingPrefs.user_prefs.default_secondary_timezone ?? [];
      const newDefaultSecondaryTz = [...currentDefaultSecondaryTz, null];

      handleSchedulingPrefChange({
        id: schedulingPrefs.user_prefs.id,
        default_secondary_timezone: newDefaultSecondaryTz
      });
    }
  // Need deep compare
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleSchedulingPrefChange, JSON.stringify(schedulingPrefs)]);

  const handleRemoveDefaultSecondaryTz = useCallback((idx: number) => {
    if (schedulingPrefs?.user_prefs && schedulingPrefs.user_prefs.default_secondary_timezone) {
      const currentDefaultTzs = schedulingPrefs.user_prefs.default_secondary_timezone;
      const secondaryTz = [...currentDefaultTzs.slice(0, idx), ...currentDefaultTzs.slice(idx + 1)];
      handleSchedulingPrefChange({
        id: schedulingPrefs.user_prefs.id,
        default_secondary_timezone: secondaryTz,
      });
    }
  // Need deep compare
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleSchedulingPrefChange, JSON.stringify(schedulingPrefs)]);

  const handleAddZoom = () => {
    navigate(PAGE_URL.INTEGRATION_SETTINGS);
  };

  return <UserPreferences 
    user={user}
    schedulingPrefs={schedulingPrefs}
    onSchedulingPrefChange={handleSchedulingPrefChangeDebounced}
    onUpdateDefaultSecondaryTz={handleUpdateDefaultSecondaryTz}
    onAddDefaultSecondaryTz={handleAddDefaultSecondaryTz}
    onRemoveDefaultSecondaryTz={handleRemoveDefaultSecondaryTz}
    openUpgradeModal={() => openGlobalModal(GlobalModalComponentName.CABINET_PROMO)}
    presetLocations={presetLocations}
    zoomSettings={zoomSettings}
    onAddZoom={handleAddZoom}
    hasMicrosoftGrant={hasMicrosoftGrant}
    hasGoogleGrant={hasGoogleGrant}
  />;
};

export default UserPreferencesContainer;