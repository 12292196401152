import { useDispatch, useSelector } from "react-redux";
import { Leader, RootState, SharedLeaderGrant, ThunkDispatchType, actions } from "../../../store";
import SupportMap from "./SupportMap";
import { useCallback, useEffect } from "react";

const SupportMapContainer = () => {
  
  const dispatch = useDispatch<ThunkDispatchType>();
  const leaders = useSelector((root: RootState) => root.leaders.orgLeaders);
  const leadersLoaded = useSelector((root: RootState) => root.leaders.orgLeadersLoaded);
  const sharedLeaderGrants = useSelector((root: RootState) => root.leaders.orgSharedLeaderGrants);
  const leaderCategories = useSelector((root: RootState) => root.leaders.orgLeaderCategories);
  const user = useSelector((root: RootState) => root.auth.user);
  const organization = useSelector((root: RootState) => root.organization);
  // const calendars = useSelector((root: RootState) => root.schedule.calendars);
  const revokeGrant = useCallback((grant: SharedLeaderGrant) => {
    dispatch(actions.leaders.revokeSharedLeaderProfile(grant.id, grant.leader, true));
  }, [dispatch]);
  const createLeader = useCallback(
    (leader: Leader, leaderPic: Blob | null, leaderPicName: string | null, idx?: number | undefined) =>
      dispatch(actions.leaders.createAdminLeader(leader, leaderPic, leaderPicName, idx)), [dispatch]);
  const deleteLeader = (leaderId: number) => dispatch(actions.leaders.deleteAdminLeader(leaderId));

  useEffect(() => {
    dispatch(actions.leaders.fetchOrganizationLeader());
    dispatch(actions.leaders.fetchOrganizationSharedLeaderGrants());
    dispatch(actions.leaders.fetchOrganizationLeaderProfileCategories());
    dispatch(actions.organization.fetchOrgUserAutocompleteOptions());
  }, [dispatch]);

  const handleCreateLeader = async (
    leader: Leader, leaderPic: Blob | null, leaderPicName: string | null, idx?: number) => {
    await createLeader(leader, leaderPic, leaderPicName);
    dispatch(actions.leaders.fetchLeaders());
  };

  return <SupportMap
    user={user}
    leaders={leaders}
    loaded={leadersLoaded}
    sharedLeaderGrants={sharedLeaderGrants}
    leaderCategories={leaderCategories}
    revokeGrant={revokeGrant}
    onCreateLeader={handleCreateLeader}
    shareWithUserOptions={organization.userAutocompleteOptions}
    onDeleteLeader={deleteLeader}
    hasEditLeaderPermission={!!user?.permissions.WRITE_LEADER_DATA_ACCESS}
  />;
};

export default SupportMapContainer;