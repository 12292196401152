import { Box, FormControl, FormLabel, Slide, Stack } from "@mui/material";
import { 
  DataGridProProps, GridFilterModel, GridSortModel, GridToolbarColumnsButton, GridToolbarExport,
  GridToolbarFilterButton 
} from "@mui/x-data-grid-pro";
import { 
  Leader, OrgUserAutocompleteOption, OrganizationLeader, RootState, SharedLeaderGrant, User 
} from "../../../store";
import { useCallback, useEffect, useMemo, useState } from "react";

import BackupTable from "./BackupTable/BackupTable";
import colors from "../../../colors";
import { useLeaderColumns } from "./LeaderCols";
import UpgradeOverlay from "../../../components/Common/UpgradeOverlay/UpgradeOverlay";
import { CabButton } from "@CabComponents/CabButton";
import { EVENT_TYPE, USER_ROLE } from "../../../constants";
import AddLeader from "@CabComponents/CabNavBar/AddLeader";
import { CabModal } from "@CabComponents/CabModal";
import { CabTextInput } from "@CabComponents/CabTextInput";
import { trackEvent } from "../../../utils/appAnalyticsUtils";
import AdminEditLeader from "./AdminEditLeader/AdminEditLeader";
import AdminReassignLeader from "./AdminReassignLeader/AdminReassignLeader";
import CabDataGrid from "@CabComponents/CabDataGrid";


export type SupportMapProps = {
  user: User | null | undefined
  leaders: OrganizationLeader[]
  sharedLeaderGrants: RootState["leaders"]["orgSharedLeaderGrants"]
  leaderCategories: RootState["leaders"]["orgLeaderCategories"]
  revokeGrant: (grant: SharedLeaderGrant) => void
  onCreateLeader: (
    leader: Leader, leaderPic: Blob | null, leaderPicName: string | null, idx?: number) => Promise<void>
  shareWithUserOptions: OrgUserAutocompleteOption[];
  onDeleteLeader: (leaderId: number) => void;
  loaded: boolean;
  hasEditLeaderPermission: boolean;
};

const slots: DataGridProProps["slots"] = {
  noRowsOverlay: () => <Box 
    height="100%"
    width="100%"
    display="flex"
    justifyContent="center"
  >
    <Box margin="auto" padding="auto">
      No Teammates Available
    </Box>
  </Box>,
  noResultsOverlay: () => <Box 
    height="100%"
    width="100%"
    display="flex"
    justifyContent="center"
  >
    <Box margin="auto" padding="auto">
      No Teammates Found
    </Box>
  </Box>,
  toolbar: () => <Box display="flex" flexDirection="row-reverse" sx={{width: "100%"}}>
    <GridToolbarExport 
      printOptions={{ disableToolbarButton: true }}
      csvOptions={{
        fields: ['name', 'role', 'primary_assistant'],
        fileName: `Cabinet_Support_Map_${new Date().toLocaleDateString('en')}_${new Date().toLocaleTimeString('en')}`,

      }}
    />
    <GridToolbarColumnsButton />
    <GridToolbarFilterButton />
  </Box>
};

export default function SupportMap({ 
  user, leaders, sharedLeaderGrants, revokeGrant, leaderCategories, onCreateLeader, shareWithUserOptions,
  onDeleteLeader, loaded, hasEditLeaderPermission
}: SupportMapProps) {
  const [backupModalLeader, setBackupModalLeader] = useState<OrganizationLeader | null>(null);
  const [editLeader, setEditLeader] = useState<OrganizationLeader | null>(null);
  const [reassignLeader, setReassignLeader] = useState<OrganizationLeader | null>(null);
  const [zIndexAdjustment, setZIndexAdjustment] = useState(false);
  const [showAddLeader, setShowAddLeader] = useState(false);
  const [deleteLeaderId, setDeleteLeaderId] = useState<number | null>(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState('');
  const onSelectLeaderBackupInfo = useCallback((leader: OrganizationLeader) => {
    return setBackupModalLeader(leader);
  }, []);

  const [filterState, setFilterState] = useState<GridFilterModel>();
  const [sortState, setSortState] = useState<GridSortModel>();
  const [paginationState, setPaginationState] = useState({ page: 0, pageSize: 20 });


  const onDeleteLeaderClick = () => {
    if (deleteLeaderId) {
      onDeleteLeader(deleteLeaderId);
      setDeleteLeaderId(null);
      setDeleteConfirmation('');
      trackEvent(EVENT_TYPE.DELETE_EXEC);
    }
  };

  const columns = useLeaderColumns({
    onSelectLeaderBackupInfo,
    onDeleteLeader: setDeleteLeaderId,
    hasEditLeaderPermission,
    onEditLeader: setEditLeader,
    onReassignLeader: setReassignLeader,
    canTransferLeader: !!user?.permissions.WRITE_LEADER_DATA_ACCESS,
  });

  const dataRows = useMemo(() => (
    leaders || []
  ), [leaders]);

  // NOTE: This is setup to prevent the the overlay table from ever being behind the
  // initial table even during transitions
  useEffect(() => {
    if (backupModalLeader) {
      setZIndexAdjustment(!!backupModalLeader);
    } else {
      setTimeout(() => {
        setZIndexAdjustment(!!backupModalLeader);
      }, 500);
    }
  }, [backupModalLeader]);

  return <Stack marginTop={4} marginLeft={{ xs: 2, lg: "auto" }} marginRight={{ xs: 2, lg: "auto" }}
    paddingBottom={12} maxWidth={900} gap={2}
  >
    
    <Box width="100%" position={"relative"} minHeight={400}>
      <Box zIndex={1} position="relative">
        {user?.features?.SUPPORT_MAP && (
          <CabButton
            onClick={() => setShowAddLeader(true)}
            sx={{width: 160}}
          >
            {user?.active_license.user_role === USER_ROLE.INDIVIDUAL ? 
              'Add Teammate' : 'Add Executive'
            }
          </CabButton>
        )}
        <CabDataGrid<OrganizationLeader>
          columns={columns}
          rows={dataRows}
          pagination
          pageSizeOptions={[20]}
          paginationModel={paginationState}
          onPaginationModelChange={setPaginationState}
          filterModel={filterState}
          onFilterModelChange={(data) => setFilterState(data)}
          sortModel={sortState}
          onSortModelChange={(data) => setSortState(data)}
          paginationMode="client"
          filterMode="client"
          sortingMode="client"
          slots={slots}
          loading={!loaded}
          autoHeight
        />
      </Box>
      <Box position={"absolute"} top={0} left={0} width="100%" height={"100%"} zIndex={zIndexAdjustment ? 2 : 0}>
        <Slide direction="left" in={!!backupModalLeader} mountOnEnter unmountOnExit>
          <Box width={"100%"} height={"100%"} sx={{backgroundColor: colors.white900}}>
            <BackupTable
              user={user}
              leader={backupModalLeader || undefined}
              grants={backupModalLeader ? sharedLeaderGrants[backupModalLeader.id] : undefined}
              categories={backupModalLeader ? leaderCategories[backupModalLeader.id] : undefined}
              closeBackupTable={() => setBackupModalLeader(null)}
              revokeGrant={revokeGrant}
            />
          </Box>
        </Slide>
      </Box>
    </Box>
    {!user?.features?.SUPPORT_MAP && hasEditLeaderPermission && (
      <UpgradeOverlay
        show={true}
        overlaySx={{
          top: 105, height: '100%', position: 'absolute', width: '100%', zIndex: 100, 
          backgroundColor: 'rgba(0, 0, 0, .8)', left: 0, justifyContent: 'center', display: 'flex',
        }}
        message={'Upgrade your subscription plan to gain access to the Support Map'} />
    )
    }
    <AddLeader 
      open={showAddLeader} 
      onClose={() => setShowAddLeader(false)}
      onCreateLeader={onCreateLeader}
      shareWithUserOptions={shareWithUserOptions}
    />
    {!!editLeader && (
      <AdminEditLeader
        isOpen={!!editLeader}
        onDidDismiss={() => setEditLeader(null)}
        leader={editLeader}
      />
    )}
    {!!reassignLeader && (
      <AdminReassignLeader
        isOpen={!!reassignLeader}
        onDidDismiss={() => setReassignLeader(null)}
        leader={reassignLeader}
        shareWithUserOptions={shareWithUserOptions}
      />
    )}
    <CabModal
      open={!!deleteLeaderId}
      onClose={() => setDeleteLeaderId(null)}
      title={`Are you sure you want to delete this leader?`}
      text={
        "All meetings, profile information, and any other data associated with the leader will be permanently deleted."
      }
      isAlert={true}
      noFullScreen={true}
      sx={{ ".MuiPaper-root": { width: "100%" } }}
      actionButtons={
        <>
          <CabButton buttonType='tertiary' color='primary' onClick={() => setDeleteLeaderId(null)}>
            Cancel
          </CabButton>
          <CabButton buttonType='primary' color='primary' onClick={onDeleteLeaderClick}
            disabled={deleteConfirmation.toLowerCase() !== 'delete'}>
            Delete
          </CabButton>
        </>
      }
    >
      <FormControl fullWidth sx={{marginTop: 2}}>
        <FormLabel>Confirmation</FormLabel>
        <CabTextInput
          value={deleteConfirmation}
          onChange={(e) => setDeleteConfirmation(e.currentTarget.value)}
          placeholder={'Type "DELETE" to confirm deletion'}
        />
      </FormControl>
    </CabModal>
  </Stack>;
}
